<template>
    <el-dialog :title="'Setari luni suspendate la ' + client.Nume " :visible.sync="showPopup" class="my-dialog-class"  :close-on-click-modal="false">
        
        <el-form label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='my-form' @submit.prevent="save" 
        v-loading="loadingVisible" >
            Selectati lunile pt suspendare:

            <div v-for="l in locatii" :key="'locatie-'+l.Id" style="padding-top: 25px; ">
                <div> {{l.Nume}} 
                    ( Mentenanta lunara: {{l.ValoareMentenantaLunara}} {{l.Valuta.Nume}}
                        <span v-if="l.Valuta.Nume == 'EUR' ">
                            = {{l.ValoareMentenantaLunaraInLei}} Lei
                        </span>
                    ) 
                    <span v-if="get_nr_luni_selectate(l.Id) > 0">
                        <el-tag size="small" effect="dark">
                         {{get_nr_luni_selectate(l.Id)}} luni selectate 
                        </el-tag>
                    </span>
                </div>
                <el-tag 
                    :class="d.Status"
                    v-for="(d,idx) in l.Date" 
                    :key="'data-' + l.Id + '-' + idx" 
                    @click="toggle_luna(l, d)"
                    style="margin-left: 5px; margin-top: 5px; min-width: 100px; text-align: center; cursor: pointer">
                    
                    <span>{{d.NumeLuna}} </span>
                    <span :class="{ 'an-diferit': d.An != new Date().getFullYear() }">
                        {{d.An}}
                    </span>
                </el-tag>
                <el-tag 
                effect="plain"
                type="warning"
                @click="load_more(l)" style="margin-left: 5px; margin-top: 5px; min-width: 100px; text-align: center; cursor: pointer">
                    Mai multe...
                </el-tag>
            </div>
        

        </el-form>
        <span slot="footer" class="dialog-footer" >
            <el-button @click="showPopup=false"> Renunta  </el-button>
            <el-button @click="save()"> 
                Salveaza
            </el-button>
        </span>
    </el-dialog>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from '@/pages/BasePage';
import moment from 'moment';

export default {
    name: "Grid_luni_suspendate_dialog",
    extends: BasePage,
    data () {
        return {
            baseUrl: '',
            showPopup: false,
            client:  { Id: -1, Nume: '' },
            locatii: [],
            selectedObject: { IdFirma:-1, IdClient: -1 },
            Info:  {},
            rules: {}
        }
    },
    methods: {
        show_me: async function( { mode, id_client } ) {
            this.showPopup               = true;
            this.mode                    = mode;

            this.selectedObject.IdClient    = id_client;
            
            var clientResponse  = await this.post("clienti/get_info_item_dialog", { id: id_client } );
            this.client         = clientResponse.Item;
            
            this.selectedObject.NrDocument   = '';
            await this.get_info_locatii_pt_adaugare();
        
        },

        async load_more(locatie) {
            var date = locatie.Date;
            var last = date[date.length-1];
            
            var moreResponse = await this.post("GridIncasari/load_more_months", { id_locatie: locatie.Id, ultima_luna: last.Data } );
            moreResponse.Months.forEach(m => {
                locatie.Date.push(m);
            });
        },

        async get_info_locatii_pt_adaugare(){
            var locatiiResponse  = await this.post("GridIncasari/get_info_locatii_pt_suspendare", { id: this.selectedObject.IdClient } );
            this.locatii         = locatiiResponse.Locatii;
            this.curs            = locatiiResponse.curs;
        },

        get_nr_luni_selectate(id_locatie) {
            var locatie  = this.locatii.find( x => x.Id == id_locatie );
            var date     = locatie.Date.filter( x => x.StatusInitial != "suspendata" && x.Status == "suspendata" );
            return date.length;
        },

        toggle_luna(_locatie, _data) {
            if(_data.StatusInitial == "neplatita"){
                //innegrim
                _data.Status = _data.Status == "suspendata" ? _data.StatusInitial : "suspendata";
                
            } else {
                this.$notify({title: 'Atentie', message: 'Aceasta data nu poate fi suspendata!', type: 'warning' });
            }
        },

        async get_info(){
            // var response = await this.post("incasare/get_info_for_dialog" );
        },      
        get_luni_selectate(){
            var ret = [];
            this.locatii.forEach(l => {
                var dateGasite = [];
                l.Date.forEach(d => {
                    if( d.Status == 'suspendata' )
                    {
                        dateGasite.push( { Data: d.Data, Status: d.Status });
                    }
                });
                if( dateGasite.length > 0) ret.push( { IdLocatie: l.Id, DateSelectate: dateGasite } );
            });

            return ret;
        },
        save: async function() {
            this.$refs['my-form'].validate( async(valid) => {
                if (valid)
                {
                    //trimitem lunile nou selectate
                    var luniSelectate   = this.get_luni_selectate();
                    var response        = await this.post("GridIncasari/save_suspendate", { object: this.selectedObject, luniSelectate: JSON.stringify( luniSelectate ) } );
                    if( response.Eroare ) {
                        this.$message.error(response.MesajEroare);
                    } else {
                        this.showPopup  = false;
                        this.$emit("save");
                    }
                
                }
            });
        }
    },
    mounted: function() {
        this.baseUrl = settings.BASE_URL;
        this.get_info();
    }
};
</script>

<style lang="less" scoped>
    .el-tag{
        -webkit-user-select: none;
        user-select: none;
        opacity: 1;
        background: white;
        font-weight: bold;
        color: black;
        &:hover{
            opacity: 0.5;
        }
    }

    .partiala-nou{
        background: yellow;
    }
    .platita-nou{
        background: green;
        color: white;
    }
    .platita{
        background: fade(green,50%);
    }
    .partiala{
        background: fade(yellow,50%);
    }
    .suspendata{
        background: fade(black,90%);
        color: white;
    }
    .an-diferit{
        color: red;
        font-style: italic;
    }
    
</style>