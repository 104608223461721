<template>
        <base-page>
        
        <el-card>
            <div>
                <div class="table" id="table" >
                    <div style="overflow: scroll;">
                        <table border="0" class="my-grid" v-loading="loadingVisible" cellpadding="0" cellspacing="0">
                            <thead>
                                <tr>
                                    <th class="sticky first-cell">Luna</th>
                                    <th class="header1-cell" v-for="(c,idx) in Clienti" :key="'client-'+idx" :colspan="c.Locatii.length" >
                                        
                                        <div :class="{'nume-client': true}">
                                            {{c.Nume}} <br/>
                                            <el-tag v-if="c.Sters=='1'" type="danger" size="mini">!! STERS !!</el-tag>
                                        </div>
                                        <hr/>
                                        <el-tooltip content="Seteaza ca default">
                                            <button style="background:#E6A23C; border: 1px solid #E6A23C " @click="seteaza_client_default( c )"><i class="el-icon-right"></i></button>
                                        </el-tooltip>
                                        <el-tooltip content="Adauga incasare">
                                            <button   @click="show_incasari_dialog( c.Id )"><i class="el-icon-plus"></i></button>
                                        </el-tooltip>
                                        <el-tooltip content="Luni suspendate">
                                            <button  @click="show_luni_suspendate_dialog( c.Id )" ><i class="el-icon-lock"></i></button>
                                        </el-tooltip>
                                        
                                        <hr/>

                                    </th>
                                </tr>
                                <tr>
                                    <th  class="sticky first-cell">
                                        <select v-model="Filters.LuniNeplatite" @change="refresh_info()" class="filtru-luni-neplatite">
                                            <option value="0">Neplatite > 0</option>
                                            <option value="1">Neplatite > 1</option>
                                            <option value="2">Neplatite > 2</option>
                                            <option value="3">Neplatite > 3</option>
                                            <option value="4">Neplatite > 4</option>
                                            <option value="5">Neplatite > 5</option>
                                            <option value="6">Neplatite > 6</option>
                                        </select>
                                        <br/>
                                        <el-tag size="mini">{{ NrLocatii }} Locatii</el-tag>
                                        <br/>
                                        <el-checkbox v-model="Filters.PerioadaExtinsa" @change="refresh_info()" true-label="1" false-label="0">Extinsa</el-checkbox>
                                        
                                    </th>
                                    <template v-for="(c,idx) in Clienti">
                                        <th
                                        v-for="(l,idx2) in c.Locatii" :key="'locatie-'+idx + '-'+ idx2"  
                                        :class="{'header2-cell': true, 'last-locatie-cell': l.LastLocatieCell}" 
                                        style="text-transform: uppercase">
                                           
                                            <div :class="{'nume-locatie': true, 'operat-in-facturier': l.OperatInFacturier == '1'}">
                                                {{l.Nume}}  
                                                
                                                
                                                <el-tooltip content="Luni neplatite">
                                                    <el-tag size="mini" style="margin: 0px 5px; font-weight: bold !important; " :type="l.NrLuniNeplatite > 3 ? 'danger' : 'success' ">{{l.NrLuniNeplatite}}</el-tag>
                                                </el-tooltip>
                                            </div>
                                            <div v-if="l.Sters=='1'">
                                                <el-tag type="danger" size="mini">Sters</el-tag>
                                            </div>

                                            <hr/>
                                        </th>
                                    </template>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(luna,idx) in Luni" :key="'row-'+idx" :class="{ 'luna-curenta': luna.EsteLunaCurenta }">
                                    <td :class="{'sticky': true, 'first-cell': true }"> {{luna.LunaFormatata}} </td>
                                    
                                    <td v-for="(c,idxC) in luna.Date" 
                                        :class="{'cell': true, 'selected': false, 
                                            'luna-curenta': c.EsteLunaCurenta, 'platita': c.Status == 'platita', 
                                            'neplatita': c.Status == 'neplatita', 
                                            'suspendata': c.Status == 'suspendata', 
                                            'partiala': c.Status == 'partiala', 
                                            'contract-neinceput': c.Status == 'contract-neinceput',
                                            'neplatita-in-viitor': c.Status == 'neplatita-in-viitor',
                                            'last-locatie-cell': c.LastLocatieCell == '1' }"
                                        :key="'cell-' + idx + '-' + idxC" >
                                        

                                        <i v-if="c.Status=='platita'"   class="el-icon-check"></i>
                                        <i v-if="c.Status=='neplatita'" class="el-icon-close"></i>
                                        <i v-if="c.Status=='partiala'"  class="el-icon-sort"></i>
                                        <i v-if="c.Status=='suspendata'"  class="el-icon-lock"></i>
                                        <i v-if="c.Status=='contract-neinceput'"  class="el-icon-minus"></i>

                                        
                                    </td>
                                </tr>
                            </tbody>
                            
                        </table>
                    </div>
                </div>
            </div>
        </el-card>

        <incasare-dialog ref="dlgIncasare" @save='refresh_info()'/>
        <luni-suspendate-dialog ref="dlgLuniSuspendate" @save="refresh_info()"/>
       
    </base-page>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import TitluPagina from '@/widgets/TitluPagina';
import eventBus from '@/backend/EventBus';
import GridIncasareDialog from '@/pages/grid-incasari/GridIncasareDialog.vue';
import GridLuniSuspendateDialog from '@/pages/grid-incasari/GridLuniSuspendateDialog.vue';
import moment from 'moment';

export default {
    name: "grid-incasari",
    extends: BasePage,
    components: {
        'base-page': BasePage,
        'titlu-pagina': TitluPagina,
        'incasare-dialog': GridIncasareDialog,
        'luni-suspendate-dialog': GridLuniSuspendateDialog
    },
    data () {
        return {
            base_url: '',
            cellWidth: 200,
            Filters: { LuniNeplatite: '0', IdClient: '-1', PerioadaExtinsa: "0" },
            Luni: [],
            Clienti: [],
            NrLocatii:0,
        }
    },
    methods: {

        async get_info() {
            if( settings.is_client_selectat_activ() ){
                this.Filters.IdClient = settings.get_id_client_selectat();
            }
            this.refresh_info();
        },

        show_incasari_dialog( id ) {
            if( !this.$has_right('adauga_incasare') )
            {
                this.$message.error('Nu aveti drepturi!');
                return;
            }
            this.$refs['dlgIncasare'].show_me( { id_client: id } );
        },
        show_luni_suspendate_dialog( id ) {
            if( !this.$has_right('adauga_suspendare') )
            {
                this.$message.error('Nu aveti drepturi!');
                return;
            }
            this.$refs['dlgLuniSuspendate'].show_me( { id_client: id } );
        },

        seteaza_client_default(client){
            settings.set_id_client_selectat  (client.Id);
            settings.set_nume_client_selectat(client.Nume);

            eventBus.$emit('change-client-event');
            this.get_info();
        },
        
        reset(){
            this.Filters = { IdClient: -1 };
            this.refresh_info();
        },

        

        async refresh_info() {
            var response   = await this.post("GridIncasari", { filtru_id_client: this.Filters.IdClient, perioada_extinsa: this.Filters.PerioadaExtinsa, filtru_nr_luni_neplatite: this.Filters.LuniNeplatite });
            this.Luni      = response.Luni;
            this.Clienti   = response.Clienti;
            this.NrLocatii = response.NrLocatii;
        }
        
    },
    mounted(){
        this.base_url = settings.BASE_URL;
        this.select_menu_item('grid-incasari');

        if( !this.$has_right("pagina_de_grid_incasari") )  this.$router.push("/no-rights");

        eventBus.$on('clear-client-event', () => {
            this.Filters.IdClient = -1;
            this.refresh_info();
        });

        this.get_info();
    }
};
</script>

<style lang="less" scoped>

    .nume-client{
        min-height: 40px;
    }
    .operat-in-facturier{
        border: 1px solid green;
        background: fade(green, 20%);
        font-weight: bold;
    }
    .nume-locatie{
        min-height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
    }
    .my-grid{

        tr.luna-curenta{
            
            td{
                border-bottom: 3px solid #409EFF;   
            }
        }
        td{
            background: rgb(240, 249, 235);
            text-align: center;
            font-size: 16px;
            padding: 2px;
            i{
                color: green;
            }
        }

        td.platita{
            background: rgb(240, 249, 235);
            border:1px dotted rgb(103, 194, 58);
            i{
                color: rgb(103, 194, 58);
            }
        }
        td.neplatita{
            background: rgb(253, 226, 226);
            border:1px dotted rgb(245, 108, 108);
            i{
                color: rgb(245, 108, 108);
            }
        }
        td.partiala{
            background: fade(yellow, 95%);
            border:1px dotted rgb(230, 162, 60);
            i{
                color: rgb(230, 162, 60);
            }
        }
        td.suspendata{
            background: fade(black, 95%);
            border:1px dotted white;
            i{
                color: white;
            }
        }
        td.contract-neinceput{
            background: white !important;
            color: gray;
            border: none;
            i{
                color: gray;
            }
        }
        td.neplatita-in-viitor{
            background: white;
            color: white;
            i{
                display: none;
            }
        }
        
    }
    .last-locatie-cell{
        border-right: 1px solid #409EFF !important;
    }
    .sticky{
        position: sticky;
        left:0;
        
    }
    .first-cell{
        min-width: 80px;
        text-align: right !important;
        padding-right: 10px;
        background-color: rgb(103, 194, 58) !important;
        color: white;
    }

    .header1-cell{
        min-width: 200px;
        text-align: center;
        font-size: 12px;
        font-weight: bold;
        border-top: 2px solid darkgray;
        padding-top: 5px;
        button{
            margin: 0px 5px;
            background: white;
            border: 1px solid black;
            &:hover{
                background: rgb(103, 194, 58);
                color: white;
                cursor: pointer;
            }
        }
    }

    .header2-cell{
        min-width: 200px;
        text-align: center;
        font-size: 10px;
        font-weight: 300;
        font-style: italic;
    }
    .luna-curenta{
        background-color: fade( blue, 50%) !important;
    }

    .btn-suspendate{
        background: black;
        color: white;
        &:hover{
            background: fade(black, 50%);
        }
    }
    .filtru-luni-neplatite{
        font-size:10px;
    }
    

</style>
