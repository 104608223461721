<template>
    <el-dialog :title="'Incasare de la ' + client.Nume + ': ' + selectedObject.Valoare + ' Lei'" :visible.sync="showPopup" class="my-dialog-class"  :close-on-click-modal="false">
        
        <el-form label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >
            <el-row :gutter="15" >
                <el-steps  :active="pas" finish-status="success" class="full-width">
                    <el-step title="Plata"></el-step>
                    <el-step title="Locatii"></el-step>
                </el-steps>
            </el-row>

            <div v-if="pas == 0">
                <el-row :gutter="10">
                    <el-col :span="24" v-if="Info.Firme.length > 1">
                        <el-form-item label='Firma' >
                            <el-select  class='full-width' v-model="selectedObject.IdFirma" @change="on_change_firma">
                                <el-option v-for="(f, idx) in Info.Firme" :key="'firma-'+ idx" :value="f.Id" :label="f.Nume" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label='Data platii' >
                            <el-date-picker  class='full-width' v-model="selectedObject.DataPlatii" type="date" placeholder="Alege data platii" value-format='yyyy-MM-dd' format='dd.MM.yyyy'/>
                        </el-form-item>
                    </el-col>
                    
                    <el-col :span="6">
                        <el-form-item label='Tip document' >
                            <el-radio-group v-model="selectedObject.TipDocument"  class='full-width'>
                                <el-radio-button label="factura" ></el-radio-button>
                                <el-radio-button label="proforma"></el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>

                    <el-col :span="6">
                        <el-form-item label='Nr document' prop="NrDocument">
                            <el-input v-model="selectedObject.NrDocument" required class='full-width'/>
                        </el-form-item>
                    </el-col>
                    
                    <el-col :span="6">
                        <el-form-item label='Valoare' >
                            <el-input-number v-model="selectedObject.Valoare" :precision="2" :step="1" :min="1" :max="10000000"/>
                        </el-form-item>
                    </el-col>
                </el-row>
            </div>

            <div v-if="pas == 1">
                <el-row>
                    <el-col :md="12">
                            Firma:<strong> {{firma.Nume}} </strong> <span v-if="firma.CotaTVA > 0">( TVA {{firma.CotaTVA}}%)</span>
                        <br/>
                        Suma incasata: {{selectedObject.Valoare}} Lei <br/>
                        Valoare mentenante selectata: {{ get_valoare_selectata() }} Lei <br/>
                        Diferenta: {{get_diferenta()}} Lei <br/>
                        <el-progress :percentage="get_diferenta_as_procent()"></el-progress>
                    </el-col>
                    <el-col :md="12">
                        <el-alert v-if="afiseazaEroareDiferentaPreaMare" type="error" :closable="false" effect="dark">
                              Atentie! Diferenta mai mare de 50 lei la valoarea lunilor selectate fata de suma incasata
                        </el-alert>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :md="12">
                        <el-checkbox v-model="modPartiale" border>Seteaza plati partiale</el-checkbox>
                    </el-col>
                    <el-col :md="12">
                        1 EUR = {{curs}} Lei
                    </el-col>
                </el-row>
                <div v-for="l in locatii" :key="'locatie-'+l.Id" style="padding-top: 25px; ">
                    <div> 
                        {{l.Nume}} - [{{l.Societate}}]
                        ( Mentenanta lunara: {{l.ValoareMentenantaLunara}} {{l.Valuta.Nume}}
                        <span v-if="l.Valuta.Nume == 'EUR' ">
                            = {{l.ValoareMentenantaLunaraInLei}} Lei
                        </span>
                        <span v-if="firma.CotaTVA > 0">
                            + {{firma.CotaTVA}}% 
                            = {{l.ValoareMentenantaLunaraInLeiCuTva}} Lei
                        </span>
                         ) </div>
                    <el-tag 
                        :class="d.Status"
                        v-for="(d,idx) in l.Date" 
                        :key="'data-' + l.Id + '-' + idx" 
                        @click="toggle_luna(l, d)"
                        style="margin-left: 5px; margin-top: 5px; min-width: 100px; text-align: center; cursor: pointer">
                    
                        <span>{{d.NumeLuna}} </span>
                        <span :class="{ 'an-diferit': d.An != new Date().getFullYear() }">
                            {{d.An}}
                        </span>

                    </el-tag>

                    <el-tag  effect="plain" type="warning"
                        @click="load_more(l)" 
                        style="margin-left: 5px; margin-top: 5px; min-width: 100px; text-align: center; cursor: pointer">
                            Mai multe...
                    </el-tag>
                </div>
            </div>

        </el-form>
        <span slot="footer" class="dialog-footer" >
            <el-button @click="prev_step()">
                <span v-if="pas==0">
                    Renunta
                </span>
                <span v-if="pas==1">
                    Pasul anterior
                </span>
            </el-button>
            <el-button @click="next_step()"> 
                <span v-if="pas==0">
                    Pasul urmator 
                </span>
                <span v-if="pas==1">
                    Salveaza
                </span>
            </el-button>
        </span>
    </el-dialog>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from '@/pages/BasePage';
import moment from 'moment';

export default {
    name: "Grid_incasare_dialog",
    extends: BasePage,
    data () {
        return {
            baseUrl :'',
            showPopup: false,
            modPartiale: false,
            afiseazaEroareDiferentaPreaMare: true,
            pas: 0,
            firma: {},
            curs: 0,
            client: { Id: -1, Nume: '' },
            locatii: [],
            
            selectedObject: { IdFirma:-1, IdClient: -1, DataPlatii: '', TipDocument: 'factura', NrDocument: '', Valoare: 0  },
            Info:{
                Firme: []
            },
            rules: {
                NrDocument: [ { required: true, message: "Campul este obligatoriu" } ]
            }
        }
    },
    methods: {
        show_me: async function( { id_client } ) {
            this.showPopup                  = true;
            
            this.selectedObject.IdClient    = id_client;
            this.selectedObject.DataPlatii  = moment().format("YYYY-MM-DD");
            this.selectedObject.TipDocument = 'factura';

            var clientResponse  = await this.post("clienti/get_info_item_dialog", { id: id_client } );
            this.client         = clientResponse.Item;

            var infoResponse    = await this.post("GridIncasari/get_info_item_dialog", { id: id_client } );
            this.Info           = infoResponse.Info;
            if( this.Info.Firme.length > 0 ) {
                this.selectedObject.IdFirma = this.Info.Firme[0].Id;
                this.firma      = this.Info.Firme[0];
            }
            
            
            this.pas                             = 0;
            this.selectedObject.NrDocument       = '';
            this.selectedObject.Valoare          = 1;
            this.modPartiale                     = false;
            this.afiseazaEroareDiferentaPreaMare = true;
        },

        async get_info_locatii_pt_adaugare(){
            var locatiiResponse  = await this.post("GridIncasari/get_info_locatii_pt_adaugare", { id: this.selectedObject.IdClient, id_firma: this.selectedObject.IdFirma } );
            this.locatii         = locatiiResponse.Locatii;
            this.curs            = locatiiResponse.curs;
        },

        on_change_firma(){
            this.firma = this.Info.Firme.find( x=>x.Id == this.selectedObject.IdFirma );
        },

        get_diferenta_as_procent(){
            var valoareIncasata  = this.selectedObject.Valoare;
            var valoareSelectata = this.get_valoare_selectata();
            if( valoareIncasata == 0 ) return 100;
            // i ... 100%
            // s ... x
            var ret = Math.round( valoareSelectata * 100 / valoareIncasata );
            if( ret > 100 ) ret = 100;
            
            return ret;
        },

        get_diferenta(){
            var valoareIncasata  = this.selectedObject.Valoare;
            var valoareSelectata = this.get_valoare_selectata();
            return (valoareIncasata - valoareSelectata).toFixed(2);
        },

        partiale_count(locatie){
            return locatie.Date.filter( x => x.Status == "partiala" || x.Status == "partiala-nou" ).length;
        },

        toggle_luna(_locatie, _data) {
            if(_data.StatusInitial == "partiala" || _data.StatusInitial == "neplatita" ){
                _data.Selected      = !_data.Selected;

                if( this.modPartiale ) {
                    // verificam locatia sa nu aiba nicio partiala,altfel nu il lasam
                    var _count  = this.partiale_count( _locatie );
                    if( _count == 0 )
                        _data.Status        = _data.Selected ? "partiala-nou" : _data.StatusInitial;
                    else
                        this.$notify({title: 'Atentie', message: 'Locatia are deja o luna platita partial!', type: 'warning' });
                } else { 
                    _data.Status        = _data.Selected ? "platita-nou"  : _data.StatusInitial;
                }
                
            } else {
                this.$notify({title: 'Atentie', message: 'Aceasta data nu poate fi selectata!', type: 'warning' });
            }
            this.verificare_valori();
        },

        async load_more(locatie) {
            var date = locatie.Date;
            var last = date[date.length-1];
            
            var moreResponse = await this.post("GridIncasari/load_more_months", { id_locatie: locatie.Id, ultima_luna: last.Data } );
            moreResponse.Months.forEach(m => {
                locatie.Date.push(m);
            });
        },

        verificare_valori() {
            var valoareIncasasta = this.selectedObject.Valoare;
            var valoareSelectata = this.get_valoare_selectata();
            var diferenta = Math.abs( valoareIncasasta - valoareSelectata );
            this.afiseazaEroareDiferentaPreaMare = diferenta >= 50;
        },

        get_valoare_selectata(){
            var ret = 0;
            this.locatii.forEach(l => {
                var valoare_mentenanta = l.ValoareMentenantaLunaraInLeiCuTva;
                l.Date.forEach(d => {
                    if( d.Status == "platita-nou" ) ret += parseFloat( valoare_mentenanta );
                });
            });

            return ret;
        },

        async prev_step(){
            if( this.pas == 1 ){
                this.pas--;
            } 
            else if( this.pas == 0 )
            {
                this.showPopup = false;
            }
        },

        async next_step(){
            if( this.pas < 1 ){
                this.pas++;
                await this.get_info_locatii_pt_adaugare();
            } 
            else  if( this.pas == 1 )
            {
                this.save();
            }
        },

        get_luni_selectate(){
            var ret = [];
            this.locatii.forEach(l => {
                var dateGasite = [];
                l.Date.forEach(d => {
                    if( d.Status == 'platita-nou' || d.Status == 'partiala-nou' )
                    {
                        dateGasite.push( { Data: d.Data, Status: d.Status });
                    }
                });
                if( dateGasite.length > 0) ret.push( { IdLocatie: l.Id, DateSelectate: dateGasite } );
            });

            return ret;
        },


        async get_info(){
            // var response = await this.post("incasare/get_info_for_dialog" );
        },      
        save: async function() {
            this.$refs['my-form'].validate( async(valid) => {
                if (valid)
                {
                    var valid2 = true;
                    if( this.afiseazaEroareDiferentaPreaMare )
                    {
                        var mesaj =  `Diferență mai mare de 50 lei la valoarea lunilor selectate față de suma încasată. Continuăm?`;
                        try{
                            var responseConfirm = await this.$confirm(mesaj, 'Atentie!!', {
                                confirmButtonText: 'DA',
                                cancelButtonText: 'Nu, am greșit',
                                type: 'warning'
                            });
                        } catch(ex) {  
                            valid2 = false 
                        }
                    }

                    if( valid2 ) {
                        //trimitem lunile nou selectate
                        var luniSelectate   = this.get_luni_selectate();

                        var response        = await this.post("GridIncasari/save", { object: this.selectedObject, atentionare: this.afiseazaEroareDiferentaPreaMare ? '1':'0', luniSelectate: JSON.stringify( luniSelectate ) } );
                        if( response.Eroare ) {
                            this.$message.error(response.MesajEroare);
                        } else {
                            this.showPopup  = false;
                            this.$emit("save");
                        }
                    }
                }
            });
        }
    },
    mounted: function() {
        this.baseUrl = settings.BASE_URL;
        this.get_info();
    }
};
</script>

<style lang="less" scoped>
    .el-tag{
        -webkit-user-select: none;
        user-select: none;
        opacity: 1;
        background: white;
        font-weight: bold;
        color: black;
        &:hover{
            opacity: 0.5;
        }
    }

    .partiala-nou{
        background: yellow;
    }
    .platita-nou{
        background: green;
        color: white;
    }
    .platita{
        background: fade(green,50%);
    }
    .partiala{
        background: fade(yellow,50%);
    }
    .suspendata{
        background: fade(black,90%);
        color: white;
    }

    .an-diferit{
        color: red;
        font-style: italic;
    }
</style>